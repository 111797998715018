export const  currency = [
    { value: 'INR', label: 'INR' },
    { value: 'AUD', label: 'AUD' },
    { value: 'GBP', label: 'GBP' },
    { value: 'USD', label: 'USD' },
    { value: 'AED', label: 'AED' },
    { value: 'ZMW', label: 'ZMW' },
    { value: 'SAR', label: 'SAR' },
    { value: 'QAR', label: 'QAR' },
    { value: 'BHD', label: 'BHD' },
    { value: 'SGD', label: 'SGD' },
    { value: 'NPR', label: 'NPR' },
    { value: 'KWD', label: 'KWD' },
    { value: 'OMR', label: 'OMR' },
    { value: 'KES', label: 'KES' },
    { value: 'MVR', label: 'MVR' },
    { value: 'IDR', label: 'IDR' },
    { value: 'THB', label: 'THB' },
    { value: 'MYR', label: 'MYR' },
    { value: 'PKR', label: 'PKR' },
    { value: 'GHS', label: 'GHS' },
    { value: 'LKR', label: 'LKR' },
    { value: 'TZS', label: 'TZS' },
    { value: 'MUR', label: 'MUR' },
].sort((a,b)=>a.label.localeCompare(b.label))

export const country = [
    { value: 'IND', label: 'India',key:'IN' },
    { value: 'AUS', label: 'Australia',key:'AU' },
    { value: 'USA', label: 'USA',key: 'US' },
    { value: 'UK', label: 'UK',key:'GB' },
    { value: 'UAE', label: 'UAE',key:'AE' },
    { value: 'ZMW', label: 'Zambia',key:'ZM' },
    { value: 'SAU', label: 'Saudi Arabia',key:'SA' },
    { value: 'QAT', label: 'Qatar',key:'QA' },
    { value: 'BHR', label: 'Bahrain',key:'BH' },
    { value: 'SGP', label: 'Singapore',key:'SG' },
    { value: 'NPL', label: 'Nepal',key:'NP' },
    { value: 'KWT', label: 'Kuwait',key:'KW' },
    { value: 'OMN', label: 'Oman',key:'OM' },
    { value: 'KEN', label: 'Kenya',key:'KE' },
    { value: 'MDV', label: 'Maldives',key:'MV' },
    { value: 'IDN', label: 'Indonesia',key:'ID' },
    { value: 'THA', label: 'Thailand',key:'TH' },
    { value: 'MYR', label: 'Malaysia',key:'MY' },
    { value: 'PAK', label: 'Pakistan',key:'PK' },
    { value: 'GHA', label: 'Ghana',key:'GH' },
    { value: 'LKA', label: 'Srilanka',key:'LK' },
    { value: 'TZA', label: 'Tanzania',key:'TZ' },
    { value: 'MUS', label: 'Mauritius',key:'MU' },  
  ].sort((a,b)=>a.label.localeCompare(b.label))